@import '../variables.scss';

.ant-notification-notice-description {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  color: white;
}

.ant-notification-notice-message {
  color: white;
  line-height: 22px;
  font-size: 14px !important;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 0 !important;
}

.anticon.ant-notification-notice-icon {
  color: white;
  height: 24px;
  width: 24px;
}

.ant-notification-notice {
  position: relative;
  white-space: normal;
  word-wrap: normal;
  width: 400px;
  max-width: calc(100vw - 48px);
  margin: 16px;
  overflow: hidden;
  line-height: 1.5715;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 $boxShadow;
}

.ant-notification-notice-success {
  background: $successGreen;
}

.ant-notification-notice-error {
  background: $errorRed;
}

.ant-notification-notice-close,
.ant-notification-notice-close:hover {
  position: absolute;
  top: 8px;
  right: 8px;
  color: white;
}
