@import 'styles/variables';
@import '~antd/dist/antd'; // FIXME: maybe, but antd isn't too modular
@import 'styles/icons';

@font-face {
  font-family: 'GE Inspira Sans';
  src:
    url('assets/fonts/ge-sans-bold.woff2') format('woff2'),
    url('assets/fonts/ge-sans-bold.woff') format('woff'),
    url('assets/fonts/ge-sans-bold.otf') format('otf'),
    url('assets/fonts/ge-sans-bold.ttf') format('ttf'),
    url('assets/fonts/ge-sans-bold.eot') format('eot');
  font-weight: bold;
}

@font-face {
  font-family: 'GE Inspira Sans';
  src:
    url('assets/fonts/ge-sans-bold-italic.woff2') format('woff2'),
    url('assets/fonts/ge-sans-bold-italic.woff') format('woff'),
    url('assets/fonts/ge-sans-bold-italic.otf') format('otf'),
    url('assets/fonts/ge-sans-bold-italic.ttf') format('ttf'),
    url('assets/fonts/ge-sans-bold-italic.eot') format('eot');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'GE Inspira Sans';
  src:
    url('assets/fonts/ge-sans-italic.woff2') format('woff2'),
    url('assets/fonts/ge-sans-italic.woff') format('woff'),
    url('assets/fonts/ge-sans-italic.otf') format('otf'),
    url('assets/fonts/ge-sans-italic.ttf') format('ttf'),
    url('assets/fonts/ge-sans-italic.eot') format('eot');
  font-style: italic;
}

@font-face {
  font-family: 'GE Inspira Sans';
  src:
    url('assets/fonts/ge-sans-regular.woff2') format('woff2'),
    url('assets/fonts/ge-sans-regular.woff') format('woff'),
    url('assets/fonts/ge-sans-regular.otf') format('otf'),
    url('assets/fonts/ge-sans-regular.ttf') format('ttf'),
    url('assets/fonts/ge-sans-regular.eot') format('eot');
  font-weight: normal;
}

@font-face {
  font-family: 'GE Serif';
  src:
    url('assets/fonts/ge-serif-bold.woff') format('woff'),
    url('assets/fonts/ge-serif-bold.otf') format('otf'),
    url('assets/fonts/ge-serif-bold.ttf') format('ttf'),
    url('assets/fonts/ge-serif-bold.eot') format('eot');
  font-weight: bold;
}

@font-face {
  font-family: 'GE Serif';
  src:
    url('assets/fonts/ge-serif-bold-italic.woff') format('woff'),
    url('assets/fonts/ge-serif-bold-italic.otf') format('otf'),
    url('assets/fonts/ge-serif-bold-italic.ttf') format('ttf'),
    url('assets/fonts/ge-serif-bold-italic.eot') format('eot');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'GE Serif';
  src:
    url('assets/fonts/ge-serif-italic.woff') format('woff'),
    url('assets/fonts/ge-serif-italic.otf') format('otf'),
    url('assets/fonts/ge-serif-italic.ttf') format('ttf'),
    url('assets/fonts/ge-serif-italic.eot') format('eot');
  font-style: italic;
}

@font-face {
  font-family: 'GE Serif';
  src:
    url('assets/fonts/ge-serif-regular.woff') format('woff'),
    url('assets/fonts/ge-serif-regular.otf') format('otf'),
    url('assets/fonts/ge-serif-regular.ttf') format('ttf'),
    url('assets/fonts/ge-serif-regular.eot') format('eot');
  font-weight: normal;
}

html,
body,
#root {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: $font-stack-sans-serif;
}

*:not(.anticon) {
  font-family: $font-stack-sans-serif;
}

body {
  padding: 0;
  color: $fontDarkBlue;
}

a {
  text-decoration: none;
}

.ant-tooltip {
  .ant-tooltip-content {
    background-color: white;
    border-color: $redShareDarkGrey;
    border-radius: 24px;

    .ant-tooltip-arrow-content {
      background: white;
    }

    .ant-tooltip-inner {
      background-color: white;
      color: $gray;
      padding-left: 12px;
    }
  }
}

.ant-input:hover:not(.ant-input-affix-wrapper .ant-input),
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input:focus:not(.ant-input-affix-wrapper .ant-input),
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus,
.ant-input-focus,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $redShareHoverBlue !important;
  -webkit-box-shadow: 0 0 0 2px $redShareHoverBlueAlpha2;
  box-shadow: 0 0 0 2px $redShareHoverBlueAlpha2;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner {
  border-color: $redShareDarkBlue;
  background: $redShareDarkBlue;
  color: white;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $redShareDarkBlue;
}

.ant-layout-sider-trigger {
  span[role="img"] {
    vertical-align: middle;
  }
}

.ant-select-dropdown {
  .rc-virtual-list-scrollbar-show {
    display: block !important;
  }
}
