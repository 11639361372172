#root {
  .App {
    .App__layout-container.ant-layout {
      .App__splash.fourofour {
        margin: 0 -1.5rem;
        background-size: auto;
      }
    }
  }
}
