.ant-form-item {
  &--last-item {
    margin-bottom: 0;
  }

  &--form-buttons {
    text-align: right;
  }
}

.ant-form-item-required::before {
  content: '';
  margin: 0;
}

.ant-form-item-label {
  line-height: 1.5;
  text-align: left;
  white-space: normal;
  font-weight: bold;
}

.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  position: relative;
  vertical-align: middle;
  margin-bottom: 3px;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters {
  white-space: nowrap;
}
