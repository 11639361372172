@import '../../styles/variables';

.App {

  // decrease content margin when sidebar nav is collapsed
  .Nav.ant-layout-sider-collapsed + .App__layout-container__content {
    margin-left: 80px;
  }

  .App__loader {
    height: 100%;
    width: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }

  .App__layout-container {
    min-height: 100vh;

    &__registration {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .App__splash {
    background-image: url('~assets/images/redshare-bg-01499.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .session-management-iframe {
    display: block;
    visibility: hidden;
    border: 0 none transparent;
  }

  .link {
    text-decoration: underline;
  }
}
