// Overrides for .ant-modal and friends.

@import "../variables";

$redShareModalDividerColor: #b1b3b3;
// TODO: Actual padding sizes.
$redShareModalPaddingHeaderVertical: 18px;
$redShareModalPaddingHorizontal: 50px;

.ant-modal-header {
  padding: 30px $redShareModalPaddingHorizontal 0;
  border: 0 none transparent;

  &::after {
    content: '';
    display: block;
    padding-top: 20px;
    border-bottom: 1px solid $redShareModalDividerColor;
  }
}

.ant-modal-footer {
  .ant-btn-primary {
    background-color: $redShareDarkBlue;
    border-color: $redShareDarkBlue;
  }
}

.ant-modal-title {
  font-size: 22px;
  line-height: 30px;
  position: relative;

  .title-icon {
    float: left;
    color: #63666a;
    width: 25px;
    height: 25px;
    left: -35px;
  }
}

.ant-modal-body {
  padding: 15px $redShareModalPaddingHorizontal 40px;
}

.filePreview {
  .ant-modal-content {
    height: 100vh;
  }
}

.ant-modal-confirm {
  white-space: pre-wrap;
  word-wrap: normal;
  margin: 8% auto;

  .ant-modal-confirm-title {
    font-size: 18px;
    font-weight: bold;
  }

  .ant-modal-body {
    padding: 15px 19px;
  }

  .ant-modal-content {
    border-top: 4px solid $yellow;
  }

  .ant-btn-primary {
    background-color: $yellow;
    color: $white;
  }

  .ant-btn-primary:hover {
    background-color: $yellowHover;
    color: $white;
  }
}

.ant-modal-create {
  width: fit-content !important;

  .ant-modal-content {
    $border-radius: 4px;

    border-radius: $border-radius;

    .ant-modal-header {
      border-top: $border-radius solid $redShareBlue;
      border-radius: $border-radius;

      &::after {
        border-bottom: none !important;
      }

      .ant-modal-title {
        font-size: 18px;
        font-weight: 700;
        line-height: 18px;
      }
    }

    .modal-content {
      .modal-description {
        display: block;

        &.small {
          width: 456px;
        }

        &.medium {
          width: 600px;
        }

        &.bold {
          font-weight: 700;
        }

        &.margin-top-extra {
          margin-top: 30px;
        }
      }

      .ant-form {
        margin: 15px 0;

        &.margin-top-extra {
          margin-top: 30px;
        }

        .ant-form-item-label {
          font-weight: 400;
        }

        .ant-radio-group {
          font-weight: 700;

          .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled) {
            background-color: $white;
            border-color: $redShareDarkBlue;

            &:hover {
              background-color: $redShareDarkBlue;
              border-color: $redShareDarkBlue;
            }

            &::before {
              background-color: transparent !important;
              border: none;
            }
          }

          .ant-radio-button-wrapper-checked,
          .ant-radio-button-wrapper-checked:active,
          .ant-radio-button-wrapper-checked:first-child,
          .ant-radio-button-wrapper-checked:last-child,
          .ant-radio-group .ant-radio-group-outline {
            color: $white;
            background-color: $redShareDarkBlue !important;
            border-color: $redShareDarkBlue;

            &:hover {
              border: none !important;
            }
          }
        }
      }

      .modal-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;
        margin-bottom: -25px;

        button {
          margin-left: 10px;
          text-transform: uppercase;
        }
      }
    }
  }
}
