@import './variables';

[fill="#005EB8"] {
  fill: currentColor;
}

.icon--primary {
  color: $redShareDarkBlue;
  fill: $redShareDarkBlue;
  width: 15px;
  height: 15px;
  margin-right: 5px;

  & * {
    fill: $redShareDarkBlue;
  }

  .st0 {
    color: $redShareDarkBlue;
    fill: $redShareDarkBlue;
  }
}

.disabled {
  color: $grayLighter1;
  fill: $grayLighter1;

  & * {
    fill: $grayLighter1;
  }

  .st0 {
    color: $grayLighter1;
    fill: $grayLighter1;
  }
}

.failed {
  & * {
    fill: $errorRed;
  }
}

svg {
  display: inline-block;
}
