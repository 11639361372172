@import '../../styles/variables';

$spin-duration: 1s;
$spin-timing-function: linear;

.ant-table {
  .ant-table-thead > tr > th {
    background-color: $backgroundGreyLight;
    text-transform: uppercase;
    font-size: 12px;
    color: $fontDarkBlue;
    font-weight: bold;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: $redShareHoverBlueLighter;
  }

  .inactive-row {
    background-color: $grayLighter4;
  }

  .in-progress {
    background-color: $redShareLightGrey;

    .ant-table-cell {
      // For spinning the ProgressIndicatorIcon
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }

      .spin {
        animation: spin $spin-duration $spin-timing-function infinite;
      }
    }
  }
}
