@import "../variables.scss";

.ant-btn {
  border-radius: 3px;
  border: none;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  font-weight: bold;

  .button-icon-left {
    margin-right: 5px;
    width: 16px;
    height: 16px;
    margin-bottom: 2px;
  }

  &:hover,
  &:active,
  &:focus {
    border-radius: 3px;
  }

  i {
    vertical-align: middle;
    font-size: 16px;
  }

  &-no-padding {
    padding: 0;
  }

  span[role="img"] {
    vertical-align: middle;
  }
}

.ant-btn-primary {
  background-color: $redShareBlue;

  &:hover,
  &:active,
  &:focus {
    background-color: $redShareHoverBlue;
  }
}

.ant-btn-ghost,
.ant-btn-secondary,
.ant-btn-default {
  background-color: $white;
  color: $redShareDarkBlue;
  border: 1px solid $redShareDarkBlue;
  box-shadow: none;

  &:hover,
  &:active,
  &:focus {
    background-color: $redShareHoverLightBlue;
    color: $redShareDarkBlue;
    border: 1px solid $redShareDarkBlue;
  }
}

.alert-button {
  background-color: $yellow;
  color: $white;

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    color: $white;
  }
}

.ant-btn-secondary-accent {
  background-color: $white;
  color: $redShareDarkBlue;
  border: 1px solid $redShareBlue;

  &:hover,
  &:active,
  &:focus {
    background-color: $redShareHoverLightBlue;
    color: $redShareDarkBlue;
    border: 1px solid $redShareDarkBlue;
  }
}

.ant-btn-link {
  color: $fontDarkBlue;
  font-weight: bold;
  box-shadow: none;
}

.ant-btn-link:hover {
  color: $redShareHoverBlue;
}

.ant-dropdown-menu-item {
  .ant-btn-link {
    padding: inherit;
    padding-left: 0;
    margin: inherit;
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;

    a {
      display: flex;
      align-items: center;
    }
  }
}

.ant-btn-primary.disabled,
.ant-btn-primary[disabled],
.ant-btn-primary.disabled:hover,
.ant-btn-primary[disabled]:hover,
.ant-btn-primary.disabled:focus,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary.disabled:active,
.ant-btn-primary[disabled]:active,
.ant-btn-primary.disabled.active,
.ant-btn-primary[disabled].active {
  background-color: $redShareDisabledButtonGrey;
  color: $redShareDisabledTextGrey;
}

.ant-input-search-button {
  border-radius: 0 5px 5px 0;

  &:hover,
  &:focus {
    border-radius: 0 5px 5px 0;
  }
}

.icon-right {
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    order: 1;
    margin-left: 8px;
    margin-right: -8px;
  }

  span {
    order: 0;
  }
}

.primary-inverse {
  border-radius: 3px !important;
  margin-right: 8px;
  color: $redShareBlue;
  box-shadow: none !important;
  border: solid 1px $redShareBlue !important;

  &:hover {
    color: $redShareHoverBlue !important;
    border: solid 1px $redShareHoverBlue !important;

    svg {
      fill: $redShareHoverBlue !important;
      stroke: $redShareHoverBlue !important;
    }
  }
}
