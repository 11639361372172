@import "../../styles/variables";

.Banner {
  position: relative;
  z-index: auto;
  background-color: $redShareTranslucentYellow;
  width: 100%;
  min-height: $headerHeight;
  overflow: auto;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;

  &:not(:first-of-type) {
    margin-top: 20px;
  }

  .close {
    width: fit-content;
    cursor: pointer;
    color: $redShareDarkBlue;
  }

  .banner-text-container {
    width: 100%;
    padding: 5px 12px;

    .banner-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0;
      color: $black;
    }
  }
}
