@import '../variables.scss';

.ant-pagination {
  .ant-pagination-item {
    border-color: $redShareDarkGrey;
    color: $redShareDarkGrey;
    border-radius: 50%;
  }

  .ant-pagination-item-link {
    border-width: 0;
  }
}
