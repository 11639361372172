// Overrides for .ant-layout-sider.

@import "../variables";

.ant-layout-sider {
  position: fixed;
  z-index: 10;
  top: $headerHeight;
  left: 0;
  height: calc(100vh - #{$headerHeight});
  background-color: $white;
  overflow: hidden;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);

  &-collapsed {
    .ant-layout-sider-collapsible-text {
      display: none;
    }
  }

  .ant-layout-sider-trigger {
    background-color: $redShareDarkBlue;
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    padding-top: 10px;

    .active {
      background-color: $redShareHoverBlueLighter;
      color: $fontDarkBlue;
      border: 1px solid;
      border-color: $redShareBlue;
      border-left-width: 4px;
      margin: 0 4px;

      svg {
        fill: $redShareDarkBlue;
      }
    }

    .ant-layout-sider-collapsible-text {
      transition-delay: 0s;
      transition-duration: 0.3s;
      transition-property: display;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      flex-wrap: nowrap;
      flex-direction: row;
      white-space: nowrap;
    }
  }
}
