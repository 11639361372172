@import './variables';

@mixin titlebar() {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    margin: 0;
  }
}

@mixin iconButton() {
  background-color: $redShareDarkBlue;
  color: #fff;

  svg {
    margin-right: 0.5rem;
    position: relative;
    fill: #fff;
  }

  span {
    position: relative;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $blue;
    border-color: $blue;
    color: white;
    border-radius: 0.3rem;
  }
}
