.ant-layout-footer {
  background: white;
  padding: 0;
  bottom: 0;
  flex-shrink: 0;

  a {
    margin-right: 5px;
    font-weight: 400;
    color: #63666a;
  }
}
