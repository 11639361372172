.ant-card {
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.ant-card-head {
  border-top: 4px solid $redShareDarkBlue;
  border-radius: 4px;
  margin-left: -1px;
  margin-right: -1px;
}
