@import '../variables';

.ant-dropdown {
  .ant-dropdown-menu-item:hover {
    background-color: $redShareHoverLightBlue;
  }

  .ant-btn-link {
    font-weight: normal;
    color: $redShareDarkBlue;
  }

  .ant-btn-link:hover {
    color: $redShareDarkBlue;
  }
}
