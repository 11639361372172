/**
 * 1. COLORS
 */

// New Color Range
$fontGrey: #a7a9ac;
$newBlue: #0870d8;
$yellow: #ffb600;
$yellowHover: #bd8700;
$errorRed: #f44336;
$lightGreen: #dbefdc;
$successGreen: #4caf50;
$backgroundGrey: #f7f9fb;
$backgroundGreyLight: rgba(240, 243, 247, 0.5);
$fontDarkBlue: #0d1623;
$boxShadow: rgba(0, 0, 0, 0.5);
$lightRed: #fee3e1;

// Masterbrand Blue Range (As of Dec 2021)
$blue: #005eb8;
$blueLighter2: #72cbe13a;
$redShareDarkBlue: #1e2f46;
$redShareHoverBlue: #017c9a;
$redShareHoverBlueLighter: #e5f7fc;
$redShareBlue: #00b5e2;
$redShareDisabledButtonGrey: #eff0f2;
$redShareDisabledTextGrey: #d8d8d8;
$redShareHoverLightBlue: rgba(0, 181, 226, 0.1);
$redShareLumosPrimary1:#053362;

// REDShare Color Palette (As of Aug 2018)
$redShareDarkRed: #600923;
$redShareHoverRed: #3e0013;
$redShareDarkGrey: #63666a;
$redShareLightGrey: #e6e6e6;
$redShareLighterGrey: #f5f5f5;
$redShareGreen: #477b16;
$redShareRed: #8d0219;
$redShareTranslucentYellow: rgba(255, 247, 229, 1);
$redShareFontBlack: rgba(0, 0, 0, 0.85);

// masterbrand monochromatic range
$grayDarker3:#595959;
$grayDarker2: #18181a;
$grayDarker1: #2f3133;
$gray: #63666a;
$grayLighter1: #a5a6a8;
$grayLighterDeleteUser:  #d8d8d8;
$grayLighter2: #d7d8db;
$grayLighter3: #ececf0;
$grayLighter4: #f4f5f8;

// RGB with alpha
$grayDarker1Alpha9: rgba(47, 49, 51, 0.9);
$grayDarker1Alpha5: rgba(47, 49, 51, 0.5);
$grayDarker1Alpha0: rgba(47, 49, 51, 0);
$whiteAlpha9: rgba(255, 255, 255, 0.9);
$whiteAlpha5: rgba(255, 255, 255, 0.5);
$whiteAlpha0: rgba(255, 255, 255, 0);
$blueAlpha9: rgba(0, 94, 184, 0.9);
$blueAlpha5: rgba(0, 94, 184, 0.5);
$blueAlpha0: rgba(0, 94, 184, 0);
$blueDarker2Alpha9: rgba(19, 41, 75, 0.9);
$blueDarker2Alpha5: rgba(19, 41, 75, 0.5);
$blueDarker2Alpha0: rgba(19, 41, 75, 0);
$blueLighter1Alpha9: rgba(0, 181, 226, 0.9);
$blueLighter1Alpha5: rgba(0, 181, 226, 0.5);
$blueLighter1Alpha0: rgba(0, 181, 226, 0);
$redShareDarkBlueAlpha2: rgba(35, 9, 96, 0.2);
$redShareHoverBlueAlpha2: rgba(19, 0, 62, 0.2);

$black: #000;
$white: #fff;

// green range
$green: #00bf6f;
$greenLighter1: #7befb2;

// accent colors
$accent-green: $green;
$accent-orange: #fe5000;

$error-red: #c00;
$warning-yellow: #f6c142;

$darkPrimaryText: #2f3133;
$lightPrimaryText: #fff;

/**
 * 2. TYPOGRAPHY
 */
$font-stack-sans-serif: "GE Inspira Sans", -apple-system, "BlinkMacSystemFont",
  "Segoe UI", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue",
  sans-serif;
$font-stack-serif: "GE Serif", serif;

/**
 * 3. MEDIA QUERY BREAKPOINTS
 */
$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1920px;

/**
 * 4. LAYOUT
 */
$headerHeight: 5rem;
$navbarWidth: 200px;
$navbarWidthSmall: 80px;
$breakpoint-lg: 1200px;
$breakpoint-md: 992px;
$breakpoint-sm: 768px;
$breakpoint-xs: 576px;
