@import '../variables';

.ant-radio-group-solid {
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: $redShareBlue;
    border-color: $redShareBlue;
    border-right-color: $redShareBlue !important;

    &:hover {
      background-color: $redShareHoverBlue;
      border-color: $redShareHoverBlue;
      border-right-color: $redShareHoverBlue !important;
    }
  }

  &:hover {
    color: $redShareBlue;
  }

  .ant-radio-button-wrapper:hover {
    color: $redShareHoverBlue;
  }
}

.ant-radio-group.ant-radio-group-outline {
  .ant-radio-button-wrapper:hover,
  .ant-radio-button-wrapper:focus {
    color: #fff !important;
    background-color: $redShareBlue;
    border: none;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .ant-radio-button-wrapper-checked,
  .ant-radio-button-wrapper-checked:active,
  .ant-radio-button-wrapper-checked:first-child,
  .ant-radio-button-wrapper-checked:last-child,
  .ant-radio-group .ant-radio-group-outline {
    border: 1px solid $redShareDarkBlue;
    color: $redShareBlue;

    &:hover {
      border: 1px solid $redShareBlue;
    }
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: $redShareBlue;
    border: none;
  }
}
